globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"HCnwtcUxk1SM5Y-2gEga9"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import LogRocket from "logrocket";

/** Indicates if the application is running in development mode */
const isDevelopment = process.env.NODE_ENV === "development";
/** Indicates if Sentry local testing is enabled */
const isLocalTesting =
  process.env.NEXT_PUBLIC_CLUB_APP_SENTRY_LOCAL_TESTING === "true";

/**
 * Common function for processing Sentry events before sending
 * Adds LogRocket session URL to the event's extra data
 * @param {Sentry.ErrorEvent} event - The Sentry error event
 * @param {Sentry.EventHint} hint - Additional information about the event
 * @returns {Sentry.ErrorEvent} The modified event
 */
const commonBeforeSend = (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
  if (isDevelopment && isLocalTesting) {
    console.log("Sentry beforeSend appelé avec:", { event, hint });
  }

  const logRocketSession = LogRocket.sessionURL;
  if (logRocketSession !== null) {
    event.extra = {
      ...event.extra,
      LogRocket: logRocketSession,
    };
  }
  return event;
};

/**
 * Sentry configuration for local environment
 * Enables debug mode and captures all traces
 */
const localConfig: Sentry.BrowserOptions = {
  dsn: process.env.NEXT_PUBLIC_CLUB_APP_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  debug: true,
  tracesSampleRate: 0.2,
  beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
    if (isDevelopment && isLocalTesting) {
      console.log("Sentry Client Event (local):", event);
    }
    return commonBeforeSend(event, hint);
  },
};

/**
 * Sentry configuration for production environment
 * Sets sampling rates for sessions and errors
 */
const productionConfig: Sentry.BrowserOptions = {
  dsn: process.env.NEXT_PUBLIC_CLUB_APP_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  debug: false,
  tracesSampleRate: 0.2,
  beforeSend: commonBeforeSend,
};

/**
 * Final Sentry configuration based on environment
 * - In development with local testing: uses localConfig
 * - In development without testing: disables Sentry (dsn: null)
 * - In production: uses productionConfig
 */
const config = isDevelopment
  ? isLocalTesting
    ? localConfig
    : { dsn: null }
  : productionConfig;

if (isDevelopment) {
  console.log(
    "Configuration Sentry utilisée:",
    isLocalTesting ? "locale" : "désactivée",
  );
}

Sentry.init(config);
